<template lang="pug">

.vc-quotation-cart-item-list
  .quotation-lists-container
    .list-header
      .label-part._icon
        .label
          .icon
            i.fa.fa-list
      .label-part.item-info
        .label {{ copyLocaleText('item_info') }}
      .label-part.quantity
        .label {{ copyLocaleText('quantity') }}
      .label-part.notice
        .label {{ copyLocaleText('precautions') }}

    cart-item(v-for="(list, index) in cartLists"
              :key="list.id"
              :index="index"
              :cart-item="list"
              :cart-service="cartService"
              :is-confirmed="isConfirmed")

</template>

<script>
import quotationCheckoutFlowMixin from '../mixins/quotation_checkout_flow_mixin.js'
import CartItem from './cart-item.vue'

export default {
  components: {
    CartItem,
  },

  mixins: [quotationCheckoutFlowMixin],

  props: {
    currentStep: {
      required: false,
    },
  },
  // data() {
  //   return {}
  // },
  computed: {
    isConfirmed() {
      if (this.currentStep && this.currentStep === 1) return false
      return ['confirmed', 'completed', 'canceled'].includes(this.quotation.state)
    },
  },
  // created() {},
  // mounted() {},
  // methods: {}
}
</script>
