<template lang="pug">
b-modal.vc-modal-link-pop-modal(:active.sync="isImageModalActive"
                                width="")
  b-carousel(:pause-info="false"
             :interval="7000"
             :arrow="false"
             :indicator-inside="false")
    b-carousel-item(v-for="(data, index) in modalLinks"
                    :key="index")
      a.link(:href="data.url")
        img.img(:src="data.image.url"
                :alt="data.alt_text")

</template>

<script>
export default {
  // components: {},
  // mixins: [],
  props: {
    useType: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isImageModalActive: false,
    }
  },

  computed: {
    modalLinks() {
      return this.$store.getters['modalLinks/allByType'](this.useType)
        .filter((link) => link.rules.activated)
        .sort((a, b) => a.position - b.position)
    },
  },

  // created() {},
  mounted() {
    this.$store
      .dispatch('modalLinks/allByUseType', {
        filter: this.useType,
      })
      .then((_) => {
        if (this.modalLinks.length > 0) this.isImageModalActive = true
      })
  },
  // methods: {}
}
</script>
