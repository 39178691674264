<template lang="pug">

.vc-product-purchase-button.option-button
  .button(@click="showVariantsSelector"
          :class="buttonClass")
    template(v-if="isQuotation")
      .icon
        i.fa.fa-plus
    template(v-if="!isQuotation")
      .icon
        i.fa.fa-shopping-cart
      template(v-if="product.is_sold_out")
        span.text {{ copyLocaleText('stock_status.no_stock') }}
      template(v-else)
        span.text {{ actionLocaleText('select_goods') }}

</template>

<script>
import VariantsSelector from './variants-selector.vue'

export default {
  components: {
    VariantsSelector,
  },
  // mixins: [],
  props: {
    product: {
      type: Object,
      required: true,
    },
  },

  // data() {
  //   return {}
  // },

  computed: {
    isQuotation() {
      return true // TODO: maybe pending on product value in future
    },

    buttonClass() {
      return {
        'is-sold-out': this.product.is_sold_out && !this.isQuotation,
        'is-dark': this.isQuotation,
      }
    },
  },

  // created() {},
  // mounted() {},
  methods: {
    showVariantsSelector() {
      if (this.product.is_sold_out && !this.isQuotation) return this.showSoldOutMessage()

      this.$buefy.modal.open({
        parent: this,
        width: 380,
        component: VariantsSelector,
        props: {
          product: this.product,
          isQuotation: this.isQuotation,
        },
      })
    },

    showSoldOutMessage() {
      this.$store.dispatch('addFlashMessage', [
        'notice',
        this.messageLocaleText('help.sorry_this_product_sold_out_currentlly'),
      ])
    },
  },
}
</script>
