export const FETCH_QUOTATIONS_SUCCESS = 'FETCH_QUOTATIONS_SUCCESS'
export const GET_RELATED_QUOTATIONS_SUCCESS = 'GET_RELATED_QUOTATIONS_SUCCESS'
export const GET_QUOTATION_SUCCESS = 'GET_QUOTATION_SUCCESS'
export const ADD_QUOTATION_SUCCESS = 'ADD_QUOTATION_SUCCESS'
export const UPDATE_QUOTATION_SUCCESS = 'UPDATE_QUOTATION_SUCCESS'
export const DELETE_QUOTATION_SUCCESS = 'DELETE_QUOTATION_SUCCESS'

export const API_REQUEST_START = 'API_REQUEST_START'
export const API_REQUEST_SUCCESS = 'API_REQUEST_SUCCESS'
export const API_REQUEST_FAIL = 'API_REQUEST_FAIL'
