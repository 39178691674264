<template lang="pug">

.vc-quotation-checkout-steps
  .odd-container
    .connect-line(:class="currentStep.key")
    .step-unit(v-for="(info, index) in stepMap"
               :key="index"
               :class="stepActiveClasses(index)"
               @click="toStep(index)")
      .name {{ info.name }}
      .dot
        .step-number {{ info.number }}

</template>

<script>
const STEP_MAP = {
  cart: 0,
  lists_confirmed: 1,
  confirmed: 2,
}

export default {
  // components: {},
  // mixins: [],
  props: {
    currentQuotation: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      // isInTravelMode: false,
      currentStepIndex: 0,
      initStep: null,
    }
  },

  computed: {
    stepMap() {
      return [
        {
          key: 'step-1',
          number: 1,
          name: this.copyLocaleText('quotation_flow.step_1_confirm_lists'),
        },
        {
          key: 'step-2',
          number: 2,
          name: this.copyLocaleText('quotation_flow.step_2_fill_in_contact_info'),
        },
        {
          key: 'step-3',
          number: 3,
          name: this.copyLocaleText('quotation_flow.step_3_complete_quotation'),
        },
      ]
    },

    currentStep() {
      return this.stepMap[this.currentStepIndex]
    },
  },

  watch: {
    currentQuotation: {
      handler() {
        this.setCurrentStep()
      },
    },
  },
  // created() {},
  mounted() {
    this.setCurrentStep()
  },

  methods: {
    setCurrentStep() {
      this.currentStepIndex = STEP_MAP[this.currentQuotation.state]
      this.initStep = this.currentStepIndex
      this.$emit('update:current-step-index', this.currentStepIndex + 1)
    },

    stepActiveClasses(stepNumber) {
      return {
        done: this.initStep > stepNumber,
        active: this.currentStepIndex === stepNumber,
      }
    },

    toStep(step) {
      if (step <= this.initStep) this.$emit('update:current-step-index', step + 1)
    },
  },
}
</script>
