import * as types from './mutation-types'
import User from '../../resource_models/user'

export const fetchRecentQuotations = ({ dispatch, commit }, options = {}) => {
  commit(types.API_REQUEST_START, 'fetchRecentQuotations')

  return new Promise((resolve, reject) => {
    User.fetchRecentQuotations(options)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'fetchRecentQuotations')
        dispatch('quotations/receiveResourcesFromRelationshipsWithReplace', response, {
          root: true,
        })

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchRecentQuotations,
            ref: {
              dispatch,
              commit,
            },
            params: options,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const fetchQuotations = ({ dispatch, commit }, options = {}) => {
  commit(types.API_REQUEST_START, 'fetchQuotations')

  return new Promise((resolve, reject) => {
    User.fetchQuotations(options)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'fetchQuotations')
        dispatch('quotations/receiveResourcesFromRelationshipsWithReplace', response, {
          root: true,
        })

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchQuotations,
            ref: {
              dispatch,
              commit,
            },
            params: options,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const getQuotation = ({ dispatch, commit }, number) => {
  commit(types.API_REQUEST_START, 'getQuotation')

  return new Promise((resolve, reject) => {
    User.getQuotation(number)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'getQuotation')
        dispatch('quotations/getResourceFromRelationship', response, {
          root: true,
        })
        dispatch('quotationLists/receiveResourcesFromRelationships', response, {
          root: true,
        })
        dispatch('productVariants/receiveResourcesFromRelationships', response, {
          root: true,
        })

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: getQuotation,
            ref: {
              dispatch,
              commit,
            },
            params: number,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const getGuestQuotation = ({ dispatch, commit }, { token, jwt }) => {
  commit(types.API_REQUEST_START, 'getQuotation')

  return new Promise((resolve, reject) => {
    User.getGuestQuotation(token, jwt)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'getQuotation')
        dispatch('quotations/getResourceFromRelationship', response, {
          root: true,
        })
        dispatch('quotationLists/receiveResourcesFromRelationships', response, {
          root: true,
        })
        dispatch('productVariants/receiveResourcesFromRelationships', response, {
          root: true,
        })

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: getQuotation,
            ref: {
              dispatch,
              commit,
            },
            params: {
              token,
              jwt,
            },
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const currentQuotation = ({ dispatch, commit }) => {
  commit(types.API_REQUEST_START, 'currentQuotation')

  return new Promise((resolve, reject) => {
    User.currentQuotation()
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'currentQuotation')
        dispatch('quotations/getResourceFromRelationship', response, {
          root: true,
        })
        dispatch('quotationLists/receiveResourcesFromRelationshipsWithReplace', response, {
          root: true,
        })
        dispatch('quotationLists/setCurrentQuotationId', response.data.data.id, {
          root: true,
        })
        dispatch('productVariants/receiveResourcesFromRelationships', response, {
          root: true,
        })

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: currentQuotation,
            ref: {
              dispatch,
              commit,
            },
            params: {},
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const addListToCart = ({ dispatch, commit }, requestBody) => {
  commit(types.API_REQUEST_START, 'addListToCart')

  return new Promise((resolve, reject) => {
    User.addListToCart(requestBody)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'addListToCart')
        dispatch('quotations/getResourceFromRelationship', response, {
          root: true,
        })
        dispatch('quotationLists/receiveResourcesFromRelationshipsWithReplace', response, {
          root: true,
        })

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: addListToCart,
            ref: {
              dispatch,
              commit,
            },
            params: requestBody,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const updateCartList = ({ dispatch, commit }, cartList) => {
  commit(types.API_REQUEST_START, 'updateCartList')

  return new Promise((resolve, reject) => {
    User.updateCartList(cartList)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'updateCartList')
        dispatch('quotations/getResourceFromRelationship', response, {
          root: true,
        })
        dispatch('quotationLists/receiveResourcesFromRelationshipsWithReplace', response, {
          root: true,
        })

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateCartList,
            ref: {
              dispatch,
              commit,
            },
            params: cartList,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const removeCartList = ({ dispatch, commit }, cartList) => {
  commit(types.API_REQUEST_START, 'removeCartList')

  return new Promise((resolve, reject) => {
    User.removeCartList(cartList)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'removeCartList')
        dispatch('quotations/getResourceFromRelationship', response, {
          root: true,
        })
        dispatch('quotationLists/receiveResourcesFromRelationshipsWithReplace', response, {
          root: true,
        })

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: removeCartList,
            ref: {
              dispatch,
              commit,
            },
            params: cartList,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const confirmLists = ({ dispatch, commit }, shippingMethodId) => {
  commit(types.API_REQUEST_START, 'confirmLists')

  return new Promise((resolve, reject) => {
    User.confirmLists(shippingMethodId)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'confirmLists')
        dispatch('quotations/getResourceFromRelationship', response, {
          root: true,
        })
        dispatch('quotationLists/receiveResourcesFromRelationshipsWithReplace', response, {
          root: true,
        })

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: confirmLists,
            ref: {
              dispatch,
              commit,
            },
            params: shippingMethodId,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const placeQuotation = ({ dispatch, commit }, quotation) => {
  commit(types.API_REQUEST_START, 'placeQuotation')

  return new Promise((resolve, reject) => {
    User.placeQuotation(quotation)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'placeQuotation')
        dispatch('quotations/getResourceFromRelationship', response, {
          root: true,
        })
        dispatch('quotationLists/receiveResourcesFromRelationshipsWithReplace', response, {
          root: true,
        })

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: placeQuotation,
            ref: {
              dispatch,
              commit,
            },
            params: quotation,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}
