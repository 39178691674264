import * as types from './mutation-types'
import Quotation from '../../resource_models/quotation'

export const all = ({ dispatch, commit }, options) => {
  commit(types.API_REQUEST_START, 'all')

  return new Promise((resolve, reject) => {
    Quotation.all(options)
      .then((response) => {
        commit(types.FETCH_QUOTATIONS_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: all,
            ref: {
              dispatch,
              commit,
            },
            params: options,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const find = ({ dispatch, commit }, id) => {
  commit(types.API_REQUEST_START, 'find')

  return new Promise((resolve, reject) => {
    Quotation.find(id)
      .then((response) => {
        commit(types.GET_QUOTATION_SUCCESS, response)
        dispatch('quotationLists/receiveResourcesFromRelationships', response, { root: true })
        dispatch('productVariants/receiveResourcesFromRelationships', response, {
          root: true,
        })
        dispatch('users/receiveResourcesFromRelationships', response, {
          root: true,
        })

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: find,
            ref: {
              dispatch,
              commit,
            },
            params: id,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const save = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'save')

  return new Promise((resolve, reject) => {
    model
      .save()
      .then((response) => {
        if (model.isNewRecord()) {
          commit(types.ADD_QUOTATION_SUCCESS, response)
        } else {
          commit(types.UPDATE_QUOTATION_SUCCESS, response)
        }

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: save,
            ref: {
              dispatch,
              commit,
            },
            params: model,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

// export const destroy = ({
//   dispatch,
//   commit
// }, model) => {
//   commit(types.API_REQUEST_START, 'destroy')

//   return new Promise((resolve, reject) => {
//     model.destroy()
//       .then(response => {
//         commit(types.DELETE_QUOTATION_SUCCESS, model.id)

//         resolve(response)
//       })
//       .catch(errors => {
//         model.errors.record(errors)
//         commit(types.API_REQUEST_FAIL, errors)
//         dispatch('errorMessageHandler', {
//           errors,
//           retryAction: destroy,
//           ref: {
//             dispatch,
//             commit
//           },
//           params: model
//         }, {
//           root: true
//         })

//         reject(errors)
//       })
//   })
// }

export const quote = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'quote')

  return new Promise((resolve, reject) => {
    model
      .quote()
      .then((response) => {
        commit(types.UPDATE_QUOTATION_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: quote,
            ref: {
              dispatch,
              commit,
            },
            params: model,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const complete = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'complete')

  return new Promise((resolve, reject) => {
    model
      .complete()
      .then((response) => {
        // commit(types.UPDATE_QUOTATION_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: quote,
            ref: {
              dispatch,
              commit,
            },
            params: model,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const fetchComments = ({ dispatch, commit }, { model, options = {} }) => {
  commit(types.API_REQUEST_START, 'fetchComments')

  return new Promise((resolve, reject) => {
    model
      .fetchComments(options)
      .then((response) => {
        dispatch('comments/receiveResourcesFromRelationshipsWithReplace', response, {
          root: true,
        })
        commit(types.API_REQUEST_SUCCESS, 'fetchComments')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchComments,
            ref: {
              dispatch,
              commit,
            },
            params: {
              model,
              options,
            },
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const addComment = ({ dispatch, commit }, { model, content }) => {
  commit(types.API_REQUEST_START, 'addComment')

  return new Promise((resolve, reject) => {
    model
      .addComment(content)
      .then((response) => {
        dispatch('comments/addResourceFromRelationship', response, {
          root: true,
        })
        commit(types.API_REQUEST_SUCCESS, 'addComment')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: addComment,
            ref: {
              dispatch,
              commit,
            },
            params: {
              model,
              content,
            },
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const cancel = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'cancel')

  return new Promise((resolve, reject) => {
    model
      .cancel()
      .then((response) => {
        commit(types.UPDATE_QUOTATION_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: cancel,
            ref: {
              dispatch,
              commit,
            },
            params: model,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const receiveResourcesFromRelationships = ({ commit }, response) => {
  return new Promise((resolve, reject) => {
    commit(types.GET_RELATED_QUOTATIONS_SUCCESS, response)

    resolve(response)
  })
}

export const receiveResourcesFromRelationshipsWithReplace = ({ commit }, response) => {
  return new Promise((resolve, reject) => {
    commit(types.FETCH_QUOTATIONS_SUCCESS, response)

    resolve(response)
  })
}

export const getResourceFromRelationship = ({ commit }, response) => {
  return new Promise((resolve, reject) => {
    commit(types.GET_QUOTATION_SUCCESS, response)

    resolve(response)
  })
}
