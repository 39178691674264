<template lang="pug">

.vc-quotation-cart-list-info
  .list-wrapper
    .list-header
      .info-section {{ copyLocaleText('item_info') }}
      .quantity {{ attributeLocaleText('quotation/list', 'quantity') }}
    .list-info-wrapper(v-for="(list, index) in cartLists")
      .info-section
        .index {{ index + 1 }}.
        .list-name {{ list.name_with_product }}
      .quantity {{ list.quantity }}
    .list-footer
      .label {{ attributeLocaleText('quotation/list', 'total') }}
      .info {{ copyLocaleText('x_item_x_quantity', { item_count: cartLists.length, quantity_count: cartListCount }) }}
</template>

<script>
export default {
  // components: {},
  // mixins: [],
  props: {
    cartLists: {
      type: Array,
      required: true,
    },
  },
  // data() {
  //   return {}
  // },
  computed: {
    cartListCount() {
      return this.$store.getters['quotationLists/fromCurrentQuotation'].reduce(
        (prevValue, currentList) => prevValue + currentList.quantity,
        0
      )
    },
  },
  // created() {},
  // mounted() {},
  // methods: {}
}
</script>
