import ModalLink from '../../resource_models/modal_link'

export const isLoading = state => {
  return state.isCallingAPI
}

export const all = state => {
  return state.result.map(id => new ModalLink(state.entities[id]))
}

export const useTypes = state => {
  return state.useTypes
}

export const allByType = state => type => {
  return state.result.map(id => new ModalLink(state.entities[id])).filter(modalLink => modalLink.use_type === type)
}

export const find = state => id => {
  return new ModalLink(state.entities[id])
}

export const meta = state => {
  return state.meta
}

export const errors = state => {
  return state.errors
}
