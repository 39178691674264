import QuotationDetailContent from '../../components/quotation/detail-content.vue'

export default {
  name: 'user-quotation-show-container',

  components: {
    QuotationDetailContent,
  },

  // mixins: [],

  // props: {},

  data() {
    return {
      quotationNumber: undefined,
    }
  },

  computed: {
    quotation() {
      return this.$store.getters['quotations/find'](this.quotationNumber)
    },

    cartLists() {
      if (this.quotation.lists) {
        return this.quotation.lists.map((list) => this.$store.getters['quotationLists/find'](list.id))
      } else {
        return []
      }
    },
  },

  // created() {},

  mounted() {
    this.quotationNumber = this.$el.dataset.quotation
    this.$store.dispatch('users/getQuotation', this.quotationNumber)
  },

  // methods: {}
}
