import QuotationCartService from '../../../shared/services/quotation_cart_service.js'
import CheckoutSteps from '../components/quotation/checkout-steps.vue'
import CartConfirmation from '../components/quotation/cart-confirmation.vue'
import CartContactInfo from '../components/quotation/cart-contact-info.vue'
import FinalConfirmation from '../components/quotation/final-confirmation.vue'

export default {
  name: 'quotation-cart-page-container',

  components: {
    CheckoutSteps,
    CartConfirmation,
    CartContactInfo,
    FinalConfirmation,
  },

  // mixins: [],

  // props: {},

  data() {
    return {
      currentStepIndex: 0,
      originalStep: 0,
      cartService: null,
      isCurrentQuotationLoading: false,
    }
  },

  computed: {
    cartLists() {
      return this.$store.getters['quotationLists/fromCurrentQuotation']
    },

    currentQuotation() {
      return this.$store.getters['quotations/find'](this.$store.getters['quotationLists/currentQuotationId'])
    },

    componentOfCurrentStep() {
      const steps = [null, 'cart-confirmation', 'cart-contact-info', 'final-confirmation']

      return steps[this.currentStepIndex]
    },
  },

  watch: {
    cartListsCount(newValue) {
      if (newValue === 0) Turbolinks.visit('/')
    },
  },

  created() {
    this.cartService = new QuotationCartService(this.$vlf, this.$store)
    this.isCurrentQuotationLoading = true

    this.cartService
      .fetchCurrentQuotation({
        force: true,
      })
      .then(() => {
        this.isCurrentQuotationLoading = false
      })
      .then(() => {
        if (this.cartLists.length === 0) {
          this.$vlf.getItem('redirect-path').then((path) => {
            Turbolinks.visit((path == 'undefined' ? null : path) || '/?cart_empty=1')
            this.$store.dispatch('addFlashMessage', ['notice', this.messageLocaleText('cart_is_empty_now')])
          })
        }
      })
      .catch((errors) => {
        this.isCurrentQuotationLoading = false
      })
  },

  // mounted() {},

  // methods: {}
}
