<template lang="pug">

.vc-quotation-detail-content
  b-loading(:active.sync="isLoading")

  template(v-if="isOrderLoaded")
    number-and-placed-date(:quotation="quotation")

    state-list(:quotation="quotation")

    .vc-quotation-cart-list-info
      .list-wrapper
        .list-header
          .info-section {{ copyLocaleText('item_info') }}
          .quantity {{ attributeLocaleText('quotation/list', 'quantity') }}
          template(v-if="isAfterQuoted")
            .quantity {{ attributeLocaleText('quotation/list', 'unit_price') }}
            .quantity {{ attributeLocaleText('quotation/list', 'sub_total') }}
        .list-info-wrapper(v-for="(list, index) in cartLists")
          .info-section
            .index {{ index + 1 }}.
            .list-name {{ list.name_with_product }}
          .quantity {{ list.quantity }}
          template(v-if="isAfterQuoted")
            .quantity {{ toMoney(list.unit_price).format({ zeroSymbol: '-' }) }}
            .quantity {{ toMoney(list.sub_total).format({ zeroSymbol: '-' }) }}
        .list-footer
          .label {{ attributeLocaleText('quotation/list', 'total') }}
          .info {{ copyLocaleText('x_item_x_quantity', { item_count: cartLists.length, quantity_count: cartListCount }) }}
          span.price(v-if="isAfterQuoted"
                     data-currency="TWD")
            | {{ toMoney(quotation.total_amount).format({ zeroSymbol: '-' }) }}

    .vc-quotation-contact-info-box.undertaker-info(v-if="isAfterQuoted")
      .section-label
        span {{ copyLocaleText('undertaker_information') }}
      .info-box
        .info-wrapper
          .label {{ attributeLocaleText('quotation', 'undertaker') }}
          .info {{ quotation.undertaker }}
        .info-wrapper
          .label {{ attributeLocaleText('quotation', 'undertaker_phone') }}
          .info {{ quotation.undertaker_phone }}
        .info-wrapper
          .label {{ attributeLocaleText('quotation', 'company_phone') }}
          .info {{ quotation.company_phone }}
        .info-wrapper
          .label {{ attributeLocaleText('quotation', 'valid_date') }}
          .info {{ dateTimeLocaleText(quotation.valid_date, 'YYYY MMMDo') }}
        .info-wrapper
          .label {{ attributeLocaleText('quotation', 'expired_date') }}
          .info {{ dateTimeLocaleText(quotation.expired_date, 'YYYY MMMDo') }}

    .vc-quotation-final-confirmation
      .section-container
        .section-column
          contact-info-box(:quotation="quotation")
        .section-column
          .section-label
            span {{ attributeLocaleText('quotation', 'notes') }}：
          .info-box
            pre.pre {{ quotation.notes }}

    option-buttons(:quotation="quotation"
                   :show-detail-link="false")

</template>

<script>
import NumberAndPlacedDate from './number-and-placed-date.vue'
import StateList from './state-list.vue'
import ContactInfoBox from './contact-info-box.vue'
import OptionButtons from './option-buttons.vue'

export default {
  components: {
    NumberAndPlacedDate,
    StateList,
    ContactInfoBox,
    OptionButtons,
  },

  props: {
    quotation: {
      type: Object,
      required: true,
    },

    cartLists: {
      type: Array,
      required: true,
    },
  },
  // data() {
  //   return {}
  // },
  computed: {
    isLoading() {
      return this.$store.getters['quotations/isLoading']
    },

    isOrderLoaded() {
      return typeof this.quotation.number === 'string'
    },

    isAfterQuoted() {
      return ['quoted', 'void'].includes(this.quotation.state)
    },

    cartListCount() {
      return this.cartLists.reduce((prevValue, currentList) => prevValue + currentList.quantity, 0)
    },
  },
  // created() {},
  // mounted() {},
  // methods: {}
}
</script>
