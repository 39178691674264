import QuotationList from '../../resource_models/quotation_list'

export const isLoading = (state) => {
  return state.isCallingAPI
}

export const all = (state) => {
  return state.result.map((id) => new QuotationList(state.entities[id]))
}

export const fromCurrentQuotation = (state) => {
  if (state.result && state.currentQuotationId) {
    return all(state).filter((list) => list.quotation.id === state.currentQuotationId)
  } else {
    return []
  }
}

export const currentQuotationId = (state) => {
  return state.currentQuotationId
}

export const rawEntities = (state) => {
  return {
    entities: state.entities,
    result: state.result,
  }
}

export const find = (state) => (id) => {
  return new QuotationList(state.entities[id])
}

export const meta = (state) => {
  return state.meta
}

export const errors = (state) => {
  return state.errors
}
