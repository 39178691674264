<template lang="pug">

.vc-order-summary-unit
  number-and-placed-date(:quotation="quotation")

  state-list(:quotation="quotation")

  .order-price-info
    span.title
      span {{ attributeLocaleText('quotation', 'total_amount') }}
      span.items-count {{ copyLocaleText('total_items_count', { count: quotation.lists.length }) }}
    span.price(data-currency="TWD") {{ toMoney(quotation.total_amount).format({ zeroSymbol: '-' }) }}

  option-buttons(:quotation="quotation")

</template>

<script>
import NumberAndPlacedDate from './number-and-placed-date.vue'
import StateList from './state-list.vue'
import OptionButtons from './option-buttons.vue'

export default {
  components: {
    NumberAndPlacedDate,
    StateList,
    OptionButtons,
  },

  // mixins: [],
  props: {
    quotation: {
      type: Object,
      required: true,
    },
  },
  // data() {
  //   return {}
  // },
  computed: {
    isLoading() {
      return this.$store.getters['users/isLoading'] || this.$store.getters['quotations/isLoading']
    },
  },
  // created() {},
  // mounted() {},
  // methods: {}
}
</script>
