<template lang="pug">

.vc-common-member-quotation-cart-button
  a.link(@click="goToCartPage")
    .icon
      i.fa.fa-shopping-cart
    span.text {{ copyLocaleText('quotation_cart') }}
    span.counter.tag.is-odd.is-small.is-rounded(v-if="cartListCount") {{ cartListCount }}

</template>

<script>
import QuotationCartService from '../../../../shared/services/quotation_cart_service.js'

export default {
  // components: {},
  // mixins: [],
  // props: {},
  data() {
    return {
      cartService: undefined,
    }
  },

  computed: {
    cartListCount() {
      return this.$store.getters['quotationLists/fromCurrentQuotation'].reduce(
        (prevValue, currentList) => prevValue + currentList.quantity,
        0
      )
    },

    _shouldFetchCurrentQuotation() {
      const doNotFetchCurrentQuotationPathes = ['/quotation_cart', '/guest/quotations/', '/user/quotations/']

      return !doNotFetchCurrentQuotationPathes.some((path) => window.location.pathname.includes(path))
    },
  },

  watch: {
    cartListCount: {
      handler(newValue) {
        if (window.location.pathname !== '/quotation_cart') return

        if (newValue === 0) {
          this.$vlf.getItem('redirect-path').then((path) => {
            Turbolinks.visit(path || '/?cart_empty=1')
          })
        }
      },
    },
  },

  // created() {},
  mounted() {
    this.cartService = new QuotationCartService(this.$vlf, this.$store)

    // NOTE: this is a workaround for fix problem that quotation item not show in guest quotation page.
    //       maybe we should store current quotation item in different namespace that wont conflict with
    //       normal quotation item (?)
    if (this._shouldFetchCurrentQuotation) this.cartService.fetchCurrentQuotation()
  },

  methods: {
    goToCartPage() {
      const currentPath = window.location.pathname

      if (currentPath === '/quotation_cart') return
      if (this.cartListCount > 0) return this._setRedirectPathAndVisitCartPage(currentPath)

      this.cartService
        .fetchCurrentQuotation({
          force: true,
        })
        .then((_) => {
          if (this.cartListCount > 0) {
            this._setRedirectPathAndVisitCartPage(currentPath)
          } else {
            this.$store.dispatch('addFlashMessage', ['notice', this.messageLocaleText('quotation_cart_is_empty_now')])
          }
        })
    },

    _setRedirectPathAndVisitCartPage(currentPath) {
      this.$vlf.setItem('redirect-path', currentPath + window.location.search).then(() => {
        Turbolinks.visit('/quotation_cart')
      })
    },
  },
}
</script>
