import Cookies from 'js-cookie'

const QUOTATION_INFO_KEY = 'quotation_cart/info'
const QUOTATION_LIST_KEY = 'quotation_cart/lists'
const QUOTATION_LIST_EXPIRES_IN_KEY = 'quotation_cart/expires-in'
const CACHE_PERIOD = 20 * 60 // 20 minutes
const RESET_FLAG = 'reset_quotation_cart'

export default class QuotationCartService {
  /**
   * constructor
   *
   * @param {Function} localForage 從 vue instance 傳入 _vm.$vlf
   * @param {Function} vuexStore 從 vue instance 傳入 _vm.$store
   */
  constructor(localForage, vuexStore) {
    this.localForage = localForage
    this.vuexStore = vuexStore
  }

  /**
   * 取得目前使用者的 currentQuotation
   * 會先檢查 localForage 中是否有 cache 住的內容。若有，會確認 cache 是否過期（暫存 20 分鐘）；若沒有，則透過 API 從 server 端
   * 取得正確內容，並將回應結果以及下次過期時間存到 localForage 中。
   *
   * @param {Object} options `force` 為 `true` 時可強制從 server 取得最新 currentQuotation 資料，忽略 locale 端的 cache。
   * @memberof ShoppingCartService
   * @returns {Promise}
   */
  fetchCurrentQuotation(
    options = {
      force: false,
    }
  ) {
    return this._tryResetCurrentQuotationLocalCache()
      .then(() => {
        return this.localForage.getItem(QUOTATION_LIST_KEY)
      })
      .then((lists) => {
        if (lists && !options.force) {
          return this.localForage.getItem(QUOTATION_LIST_EXPIRES_IN_KEY).then((localCacheExpiredAt) => {
            if (localCacheExpiredAt && localCacheExpiredAt < this._currentTime())
              return this._fetchQuotationListFromServer()

            return this.vuexStore
              .dispatch('quotationLists/storeStatesFromLocal', lists)
              .then(() => {
                return this.localForage.getItem(QUOTATION_INFO_KEY)
              })
              .then((currentQuotationId) => {
                return this.vuexStore.dispatch('quotationLists/setCurrentQuotationId', currentQuotationId)
              })
          })
        } else {
          return this._fetchQuotationListFromServer()
        }
      })
  }

  addListToCart(requestBody) {
    return this.vuexStore.dispatch('users/addListToCart', requestBody).then(() => {
      return this._cacheQuotationListsToLocalForage()
    })
  }

  updateCartList(cartList) {
    return this.vuexStore.dispatch('users/updateCartList', cartList).then(() => {
      return this._cacheQuotationListsToLocalForage()
    })
  }

  removeCartList(cartList) {
    return this.vuexStore.dispatch('users/removeCartList', cartList).then(() => {
      return this._cacheQuotationListsToLocalForage()
    })
  }

  confirmLists() {
    return this.vuexStore.dispatch('users/confirmLists').then(() => {
      return this._cacheQuotationListsToLocalForage()
    })
  }

  placeQuotation(quotation) {
    return this.vuexStore.dispatch('users/placeQuotation', quotation).then(() => {
      return this._cacheQuotationListsToLocalForage()
    })
  }

  completeQuotation(quotation) {
    return this.vuexStore.dispatch('quotations/complete', quotation).then((_) => {
      return this._clearCacheFromLocalForage()
    })
  }

  // ======================================================================== //

  _fetchQuotationListFromServer() {
    return this.vuexStore
      .dispatch('users/currentQuotation')
      .then((response) => {
        return this.localForage.setItem(QUOTATION_INFO_KEY, response.data.data.id)
      })
      .then(() => {
        return this._cacheQuotationListsToLocalForage()
      })
  }

  _cacheQuotationListsToLocalForage() {
    return this.localForage
      .setItem(QUOTATION_LIST_KEY, this.vuexStore.getters['quotationLists/rawEntities'])
      .then(() => {
        return this.localForage.setItem(QUOTATION_LIST_EXPIRES_IN_KEY, this._currentTime() + CACHE_PERIOD)
      })
  }

  _clearCacheFromLocalForage() {
    return this.localForage
      .removeItem(QUOTATION_LIST_KEY)
      .then(() => {
        return this.localForage.removeItem(QUOTATION_LIST_EXPIRES_IN_KEY)
      })
      .then(() => {
        return this.localForage.removeItem(QUOTATION_INFO_KEY)
      })
  }

  _currentTime() {
    return Math.floor(Date.now() / 1000)
  }

  _tryResetCurrentQuotationLocalCache() {
    if (Cookies.get(RESET_FLAG) === '1') {
      return this._clearCacheFromLocalForage().then(() => {
        Cookies.remove(RESET_FLAG)
      })
    } else {
      return Promise.resolve(true)
    }
  }
}
