<template lang="pug">

.vc-order-cart-confirmation.odd-container.has-padding
  b-loading(:active="isLoading")

  cart-item-list(:quotation="quotation"
                 :cart-lists="cartLists"
                 :current-step="currentStep"
                 :cart-service="cartService")

  .cart-options
    .vc-order-back-to-store-button.button.is-outlined.is-odd.back-to-store(@click="backToStore") {{ actionLocaleText('continue_look_around') }}
    .button.is-odd.back-to-store.next-step(@click="confirmLists"
                                           :class="{ 'is-loading': isLoading }") {{ actionLocaleText('next_step_is', { content: copyLocaleText('quotation_flow.step_2_fill_in_contact_info') }) }}

</template>

<script>
import quotationCheckoutFlowMixin from '../mixins/quotation_checkout_flow_mixin.js'
import CartItemList from './cart-item-list.vue'

export default {
  components: {
    CartItemList,
  },

  mixins: [quotationCheckoutFlowMixin],

  methods: {
    confirmLists() {
      if (this.quotation.state === 'cart') {
        this.cartService
          .confirmLists()
          .then(() => {
            this._scrollToTop()
          })
          .catch((_) => {
            this._scrollToTop()
          })
      } else {
        this.cartService
          .confirmLists()
          .then(() => {
            return this.$emit('update:current-step', 2)
          })
          .then(() => {
            this._scrollToTop()
          })
      }
    },
  },
}
</script>
