<template lang="pug">

.vc-order-state-list
  .state-unit.order-state(:class="quotation.quotationStateType()")
    span.title {{ attributeLocaleText('quotation', 'aasm_state') }}
    span.content {{ aasmStateLocaleText('quotation', 'state', quotation.state) }}

</template>

<script>
export default {
  // components: {},
  // mixins: [],
  props: {
    quotation: {
      type: Object,
      required: true,
    },
  },
  // data() {
  //   return {}
  // },
  // computed: {},
  // created() {},
  // mounted() {},
  // methods: {}
}
</script>
