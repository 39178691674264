import backendPaginateFilterSortAndSearchableMixin from '../../../../shared/vue_mixins/backend_paginate_filter_sort_and_searchable_mixin.js'
import QuotationFilters from '../../components/dashboard/quotation-filters.vue'
import QuotationSummaryUnit from '../../components/quotation/summary-unit.vue'
import QuotationCartService from '../../../../shared/services/quotation_cart_service.js'
import OddPagination from '../../components/common/odd-pagination.vue'

export default {
  name: 'user-order-list-container',

  components: {
    QuotationFilters,
    QuotationSummaryUnit,
    OddPagination,
  },

  mixins: [backendPaginateFilterSortAndSearchableMixin],

  // props: {},

  data() {
    return {
      resourceType: 'quotations',
      currentPage: 1,
      pageSize: 20,
      sortOrder: 'desc',
      sortField: 'completed_at',
      currentFilter: 0,
      availableFilters: ['inquiry', 'quoted', 'void'],
      isSearchOptionsOpen: false,
      searchOptions: {},
      cartService: new QuotationCartService(this.$vlf, this.$store),
      orderIds: [],
    }
  },

  computed: {
    quotations() {
      return this.$store.getters['quotations/all'].filter((order) => this.orderIds.includes(order.id))
    },

    hasQuotations() {
      return this.quotations.length > 0
    },
  },

  // created() {},

  mounted() {
    this.fetchingInitialData()
  },

  methods: {
    fetchData(options) {
      this.currentPage = options.pageNumber
      this.$store.dispatch(`users/fetchQuotations`, options).then((response) => {
        this.orderIds = response.data.data.map((order) => order.id)
      })
    },

    filterUpdatedHandler(filterName) {
      const indexOfUpdatedFilter = this.availableFilters.indexOf(filterName)

      this.currentFilter = indexOfUpdatedFilter
      this.currentPage = 1
      this.filterOnChangeHandler(indexOfUpdatedFilter)
    },
  },
}
