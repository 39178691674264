import moment from 'moment'
import FormBase from 'odd-form_object'

export default class UserForm extends FormBase {
  constructor(model) {
    super(model)

    if (!!this.wish_delivery_date) this.wish_delivery_date = new Date(this.wish_delivery_date * 1000)
  }

  sync() {
    let editedModel = this.constructor.dataDumper(this, this.model, this.originalData)

    if (editedModel.wish_delivery_date) editedModel.wish_delivery_date = moment(editedModel.wish_delivery_date).unix()

    return editedModel
  }
}
