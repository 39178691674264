<template lang="pug">

.vc-quotation-final-confirmation.odd-container.has-padding
  .section-container
    .section-column
      contact-info-box(:quotation="quotation")

    .section-column
      //- cart lists info
      .section-label
        span {{ copyLocaleText('inquiry_lists_info') }}
      cart-list-info(:cart-lists="cartLists")

      .section-label
        span {{ attributeLocaleText('quotation', 'notes') }}：
      .info-box
        pre.pre {{ quotation.notes }}

  .cart-options
    .button.is-outlined.is-odd.back-button(@click="$emit('update:current-step-index', 2)")
      | {{ actionLocaleText('prev_step') }}
    form.next-step-button(:action="completePath"
                                 method="post"
                                 ref="complete-form")
      button.button.is-odd(type="submit"
                           @click.prevent="completeQuotation"
                           :class="{ 'is-loading': isProcessing }") {{ actionLocaleText('submit_x', { x: copyLocaleText('quotation_cart') }) }}

</template>

<script>
import quotationCheckoutFlowMixin from '../mixins/quotation_checkout_flow_mixin.js'
import CartListInfo from './cart-list-info.vue'
import ContactInfoBox from './contact-info-box.vue'

export default {
  components: {
    CartListInfo,
    ContactInfoBox,
  },

  mixins: [quotationCheckoutFlowMixin],
  // props: {},
  data() {
    return {
      completePath: null,
      isProcessing: false,
    }
  },
  // computed: {},
  // created() {},
  // mounted() {},
  methods: {
    completeQuotation() {
      this.isProcessing = true
      this.cartService.completeQuotation(this.quotation).then((_) => {
        this.completePath = `/thanks_for_inquiry?quotation_number=${this.quotation.number}`

        const completeForm = this.$refs['complete-form']
        let input = document.createElement('input')

        input.setAttribute('type', 'hidden')
        input.setAttribute('name', 'quotation_number')
        input.setAttribute('value', this.quotation.number)
        completeForm.appendChild(input)
        setTimeout(() => {
          completeForm.submit()

          this.isProcessing = false
        }, 500)
      })
    },
  },
}
</script>
