import FbMessenger from '../components/common/fb-messenger.vue'
import debounce from 'lodash.debounce'
import logoV from '../../images/logo-v.png'

const MOBILE_BREAK_POINT = 769

export default {
  name: 'site-footer-container',

  components: {
    FbMessenger,
  },

  // mixins: [],

  // props: {},

  data() {
    return {
      windowWidth: 0,
      firstOpened: false,
      secondOpened: false,
      thirdOpened: false,
    }
  },

  computed: {
    logoV() {
      return logoV
    },

    isMobile() {
      return this.$store.getters['windowWidth'] < MOBILE_BREAK_POINT
    },

    categories() {
      return this.$store.getters['productCategories/allTopLevel']
    },
  },

  created() {
    if (this.categories.length === 0)
      this.$store.dispatch('productCategories/all', {
        sort: 'position',
      })
  },

  methods: {
    goWithCategory(category) {
      Turbolinks.visit(`/products?category_id=${category.id}`)
    },

    toggleList(target) {
      this[target] = !this[target]
    },
  },
}
