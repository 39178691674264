import SlideshowContainer from '../components/common/slideshow-container.vue'
import HotProducts from '../components/product/hot-products.vue'
import SelectProducts from '../components/product_category/select-products.vue'
import Product from '../../../shared/resource_models/product.js'
import MobileQuickLinkWrapper from '../components/common/mobile-quick-link-wrapper.vue'
import InfoLinkUseTypeContainer from '../components/info_link/use-type-container.vue'
import PopModal from '../components/modal_link/pop-modal.vue'

export default {
  name: 'landing-page-container',

  components: {
    SlideshowContainer,
    HotProducts,
    SelectProducts,
    MobileQuickLinkWrapper,
    InfoLinkUseTypeContainer,
    PopModal,
  },

  // mixins: [],

  // props: {},

  // data() {
  //   return {}
  // },

  // computed: {},

  // created() {},

  // mounted() {},

  // methods: {}
}
