export default {
  props: {
    quotation: {
      type: Object,
      required: true,
    },

    cartLists: {
      type: Array,
      required: true,
    },

    cartService: {
      type: Object,
      required: true,
    },

    currentStep: {
      type: Number,
      required: true,
    },
  },

  // data() {
  //   return {}
  // },

  computed: {
    isLoading() {
      return this.$store.getters['users/isLoading'] || this.$store.getters['quotations/isLoading']
    },

    errors() {
      return this.$store.getters['users/errors']
    },
  },

  methods: {
    backToStore() {
      this.$vlf.getItem('redirect-path').then((path) => {
        Turbolinks.visit(path || '/')
      })
    },

    _scrollToTop() {
      window.scroll({
        top: 0,
        behavior: 'smooth',
      })
    },
  },
}
