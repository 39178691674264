import ResourceModelBase from 'odd-resource_model'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'quotation_lists',
  attributes: ['id', 'name_with_product', 'unit_price', 'sub_total', 'quantity', 'variant', 'quotation'],
  editableAttributes: ['unit_price', 'quantity'],
}

export default class QuotationList extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  // extra methods or helpers here...
}
