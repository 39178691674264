<template lang="pug">

.vc-order-option-buttons
  //- Comment
  comment-button(:quotation="quotation"
                 :class="isOnlyButtonState")

  //- Detail Link
  a.button.is-outlined.is-default(v-if="showDetailLink"
                                  :href="quotationDetailPath") {{ copyLocaleText('quotation_detail') }}

  //- Cancel
  a.button.is-outlined.is-default(v-if="quotation.canCancelBy(currentUser)"
                                  @click="confirmCancel") {{ actionLocaleText('cancel_quotation') }}

</template>

<script>
import ActionConfirmService from '../../../../shared/services/action_confirm_service.js'
import CommentButton from './comment-button.vue'

export default {
  components: {
    CommentButton,
  },

  props: {
    showDetailLink: {
      type: Boolean,
      required: false,
      default: true,
    },

    quotation: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isOnlyOneOption: false,
    }
  },

  computed: {
    quotationDetailPath() {
      return `/user/quotations/${this.quotation.number}`
    },

    isOnlyButtonState() {
      return {
        'is-alone': this.isOnlyOneOption,
      }
    },
  },
  // created() {},
  mounted() {
    this._detactButtonLength()
  },

  methods: {
    confirmCancel() {
      new ActionConfirmService({
        title: this.messageLocaleText('confirmations.are_you_sure_want_to_cancel_quotation'),
        text: this.messageLocaleText('confirmations.this_action_can_not_retrieve'),
      }).confirm(this.cancelQuotation)
    },

    cancelQuotation() {
      this.$store
        .dispatch('quotations/cancel', this.quotation)
        .then((_) => {
          return this.$emit('quotation-canceled')
        })
        .then((_) => {
          return this.$store.dispatch('addFlashMessage', [
            'success',
            this.messageLocaleText('quotation_canceled_successfully'),
          ])
        })
    },

    _detactButtonLength() {
      if (this.showDetailLink) return

      if (this.$el.querySelectorAll('.button').length === 1) {
        this.isOnlyOneOption = true
      }
    },
  },
}
</script>
