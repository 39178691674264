<template lang="pug">

.vc-dashboard-order-filters.quotation
  //- link mode
  template(v-if="interactionMode === 'link'")
    a.filter-unit(v-for="filter in availableFilters"
                  :href="filter.path")
      .icon
        i.fa(:class="filter.icon")
      .text {{ aasmStateLocaleText('quotation', 'state', filter.name) }}
  //- filter mode
  template(v-else)
    a.filter-unit.filter-mode(v-for="filter in availableFilters"
                              @click="filterOn(filter.name)"
                              :class="{ 'is-active': filter.name === currentFilter }")
      .icon
        i.fa(:class="filter.icon")
      .text {{ aasmStateLocaleText('quotation', 'state', filter.name) }}

</template>

<script>
export default {
  // components: {},
  // mixins: [],
  props: {
    interactionMode: {
      type: String,
      required: false,
      default: () => {
        return 'link'
      },
      validator: (value) => {
        return ['link', 'filter'].includes(value)
      },
    },

    currentFilter: {
      type: String,
      required: false,
    },
  },
  // data() {
  //   return {}
  // },
  computed: {
    availableFilters() {
      return [
        {
          name: 'inquiry',
          icon: 'fa-file-o',
          path: '/user/quotations?filter=inquiry',
        },
        {
          name: 'quoted',
          icon: 'fa-file-text',
          path: '/user/quotations?filter=quoted',
        },
        {
          name: 'void',
          icon: 'fa-trash',
          path: '/user/quotations?filter=void',
        },
      ]
    },
  },
  // created() {},
  // mounted() {},
  methods: {
    filterOn(name) {
      this.$emit('quotation-filter-updated', name)
    },
  },
}
</script>
