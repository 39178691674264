<template lang="pug">

.vc-quotation-cart-contact-info.odd-container.has-padding
  b-loading(:active.sync="isLoading")

  .form-container
    .form-column
      //- email
      .section-label
        span {{ copyLocaleText('contact_person_information') }}
      .form-section
        b-field(:label="attributeLocaleText('quotation', 'contact_name')"
                :type="errors.errorClassAt('contact_name')"
                :message="errors.get('contact_name')")
          b-input(v-model="form.contact_name"
                  required
                  @keyup.native="formEditedHandler"
                  @input="errors.clear('contact_name')")
        b-field(:label="attributeLocaleText('quotation', 'contact_email')"
                :type="errors.errorClassAt('contact_email')"
                name="email"
                :message="errors.get('contact_email')")
          b-input(type="email"
                  v-model="form.contact_email"
                  required
                  @input="errors.clear('contact_email')"
                  autocomplete="contact_email"
                  :loading="isEmailChecking"
                  @keyup.native="formEditedHandler"
                  @blur="checkForExistedMember")
        b-field(:label="attributeLocaleText('quotation', 'contact_phone')"
                :type="errors.errorClassAt('contact_phone')"
                :message="errors.get('contact_phone')")
          b-input(v-model="form.contact_phone"
                  required
                  @keyup.native="formEditedHandler"
                  @input="errors.clear('contact_phone')")
        b-field(:label="attributeLocaleText('quotation', 'wish_delivery_date')"
                :type="errors.errorClassAt('wish_delivery_date')"
                :message="errors.get('wish_delivery_date')")
          b-field
            b-datepicker(v-model="form.wish_delivery_date"
                        :mobile-native="false"
                        :min-date="new Date()"
                        @input="formEditedHandler")
        b-field(:label="attributeLocaleText('quotation', 'line_id')"
                :type="errors.errorClassAt('line_id')"
                :message="errors.get('line_id')")
          b-input(v-model="form.line_id"
                  @keyup.native="formEditedHandler"
                  @input="errors.clear('line_id')")
        b-field(:label="attributeLocaleText('quotation', 'shipping_address')"
                :type="errors.errorClassAt('shipping_address')"
                :message="errors.get('shipping_address')")
          b-input(v-model="form.shipping_address"
                  @keyup.native="formEditedHandler"
                  @input="errors.clear('shipping_address')")
      .section-label
        span {{ copyLocaleText('company_information') }}
      .form-section
        b-field(:label="attributeLocaleText('quotation', 'company_name')"
                :type="errors.errorClassAt('company_name')"
                :message="errors.get('company_name')")
          b-input(v-model="form.company_name"
                  @keyup.native="formEditedHandler"
                  @input="errors.clear('company_name')")
        b-field(:label="attributeLocaleText('quotation', 'ein')"
                :type="errors.errorClassAt('ein')"
                :message="errors.get('ein')")
          b-input(v-model="form.ein"
                  @keyup.native="formEditedHandler"
                  @input="errors.clear('ein')")
        b-field(:label="attributeLocaleText('quotation', 'company_phone')"
                :type="errors.errorClassAt('company_phone')"
                :message="errors.get('company_phone')")
          b-input(v-model="form.company_phone"
                  @keyup.native="formEditedHandler"
                  @input="errors.clear('company_phone')")
        b-field(:label="attributeLocaleText('quotation', 'company_fax')"
                :type="errors.errorClassAt('company_fax')"
                :message="errors.get('company_fax')")
          b-input(v-model="form.company_fax"
                  @keyup.native="formEditedHandler"
                  @input="errors.clear('company_fax')")
        b-field(:label="attributeLocaleText('quotation', 'company_address')"
                :type="errors.errorClassAt('company_address')"
                :message="errors.get('company_address')")
          b-input(v-model="form.company_address"
                  @keyup.native="formEditedHandler"
                  @input="errors.clear('company_address')")

    .form-column.-clean
      //- cart lists info
      .section-label
        span {{ copyLocaleText('inquiry_lists_info') }}
      .form-section
        cart-list-info(:cart-lists="cartLists")
      //- notes
      .section-label
        span {{ attributeLocaleText('quotation', 'notes') }}
      .form-section
        b-field(:type="errors.errorClassAt('notes')"
                :message="errors.get('notes')")
          b-input(type="textarea"
                  v-model="form.notes"
                  @input="formEditedHandler(); errors.clear('notes')")

      //- aggrements
      .section-label
        span {{ copyLocaleText('user_aggrement_and_notes') }}
      .form-section.checkboxes
        .field
          b-checkbox(v-model="form.is_agree_with_term_of_service"
                     size="is-small"
                     type="is-odd")
            span(v-html="copyLocaleText('i_agree_with_terms_of_service_and_privacy_policy')")
        .field(v-if="currentUser.isGuest()")
          b-checkbox(v-model="form.is_agree_to_join_membership"
                     size="is-small"
                     type="is-odd") {{ copyLocaleText('join_membership_after_inquiry', { site_name: copyLocaleText('site_basics.site_name') }) }}
        .field
          b-checkbox(v-model="form.is_agree_to_receive_news_letters"
                     size="is-small"
                     type="is-odd") {{ copyLocaleText('i_would_like_to_receive_newsletters') }}

  .cart-options
    .button.is-outlined.is-odd.back-button(@click="$emit('update:current-step-index', 1)") {{ actionLocaleText('prev_step') }}
    .button.is-odd.next-step-button(@click="placeQuotation"
                                    :disabled="!form.is_agree_with_term_of_service"
                                    :class="{ 'is-loading': isLoading }") {{ actionLocaleText('next_step_is', { content: copyLocaleText('quotation_flow.step_3_complete_quotation') }) }}

</template>

<script>
import quotationCheckoutFlowMixin from '../mixins/quotation_checkout_flow_mixin.js'
import CartListInfo from './cart-list-info.vue'
import Form from '../../../../shared/forms/quotation_form.js'
import ActionConfirmService from '../../../../shared/services/action_confirm_service.js'

export default {
  components: {
    CartListInfo,
  },

  mixins: [quotationCheckoutFlowMixin],
  // props: {},
  data() {
    return {
      isFormEdited: false,
      form: new Form(Object.assign(this.quotation, this.quotation.formTemplate())),
      isEmailChecking: false,
      isEmailChanged: false,
    }
  },

  // computed: {},

  watch: {
    'form.contact_email': {
      handler(newEmail) {
        this.isEmailChanged = true
      },
    },

    'form.is_agree_to_join_membership': {
      handler() {
        this.formEditedHandler()
      },
    },
  },
  // created() {},
  mounted() {
    this.form.contact_email = this.quotation.contact_email
  },

  methods: {
    checkForExistedMember() {
      if (this.isUserSignedIn || !this.isEmailChanged) return

      this.isEmailChecking = true
      this.$store
        .dispatch('users/check', this.form.contact_email)
        .then((result) => {
          if (result.data.data.result) this._showLoginRequestAlert()
        })
        .finally((_) => {
          this.isEmailChecking = false
          this.isEmailChanged = false
        })
    },

    _showLoginRequestAlert() {
      new ActionConfirmService({
        type: 'question',
        title: this.messageLocaleText('confirmations.would_you_like_to_login_as_a_member'),
        text: this.messageLocaleText(
          'help.if_place_order_as_a_guest_you_will_need_to_check_email_for_tracking_your_order_status'
        ),
        confirmButtonText: this.actionLocaleText('shopping_as_a_member'),
        cancelButtonText: this.actionLocaleText('shopping_as_a_guest'),
        reverseButtons: true,
      }).confirm(this._goToLoginPage)
    },

    _goToLoginPage() {
      Turbolinks.visit('/user/login')
    },

    formEditedHandler() {
      this.isFormEdited = true
    },

    placeQuotation() {
      if (!this.form.is_agree_with_term_of_service) return

      if (this.quotation.state === 'lists_confirmed' || this.isFormEdited) {
        this.cartService
          .placeQuotation(this.form.sync())
          .then(() => {
            this.$emit('update:current-step-index', 3)
          })
          .catch((errors) => {
            if (errors.response.status === 500) this.$emit('update:current-step-index', 1)
          })
      } else {
        this.$emit('update:current-step-index', 3)
      }

      this._scrollToTop()
    },
  },
}
</script>
