import axios from 'axios'
import ResourceModelBase from 'odd-resource_model'
import FetchingDataOptionsService from 'odd-fetching_data_options_service'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'quotations',
  attributes: [
    'id',
    'number',
    'state',
    'total_amount',
    'email_sent_at',
    'has_new_comment',
    'has_new_reply',
    'contact_email',
    'contact_name',
    'contact_phone',
    'ein',
    'company_name',
    'company_phone',
    'company_fax',
    'company_address',
    'notes',
    'undertaker',
    'undertaker_phone',
    'undertaker_email',
    'valid_date',
    'expired_date',
    'created_at',
    'updated_at',
    'lists',
    'state',
    'is_agree_to_join_membership',
    'shipping_address',
    'wish_delivery_date',
    'line_id',
    'completed_at',
  ],
  editableAttributes: [
    'contact_email',
    'contact_name',
    'contact_phone',
    'ein',
    'company_name',
    'company_phone',
    'company_fax',
    'company_address',
    'notes',
    'undertaker',
    'undertaker_phone',
    'undertaker_email',
    'valid_date',
    'expired_date',
    'lists',
    'is_agree_to_join_membership',
    'is_agree_with_term_of_service',
    'is_agree_to_receive_news_letters',
    'shipping_address',
    'wish_delivery_date',
    'line_id',
  ],
}

export default class Quotation extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  quote() {
    return axios.put(`${this.apiBasePath()}/${this.id}/quote`)
  }

  complete() {
    return axios.put(`${this.apiBasePath()}/${this.id}/complete`)
  }

  // extra methods or helpers here...
  formTemplate() {
    return {
      is_agree_with_term_of_service: true,
      is_agree_to_receive_news_letters: true,
    }
  }

  quotationStateType() {
    return {
      'is-default': ['inquiry'].includes(this.state),
      'is-success': this.state === 'quoted',
      'is-danger': this.state === 'void',
    }
  }

  /**
   * 詢價單是否可取消。 尚未出貨前都可以取消詢價單。（給 QuotationService::Canceler 處理）
   *
   * @returns {Boolean}
   */
  canCancelBy(user) {
    return ['inquiry', 'quoted'].includes(this.state)
  }

  cancel() {
    return axios.put(`${this.apiBasePath()}/${this.id}/cancel`)
  }

  fetchComments(options = {}) {
    return axios.get(`${this.apiBasePath()}/${this.id}/comments?${FetchingDataOptionsService.call(options)}`)
  }

  addComment(content) {
    const requestBody = {
      data: {
        type: 'add_comment',
        attributes: {
          content,
        },
      },
    }

    return axios.post(`${this.apiBasePath()}/${this.id}/comments`, requestBody)
  }

  canQuote() {
    return ['inquiry', 'quoted'].includes(this.state) && !!this.undertaker
  }
}
