export const FETCH_QUOTATION_LISTS_SUCCESS = 'FETCH_QUOTATION_LISTS_SUCCESS'
export const GET_RELATED_QUOTATION_LISTS_SUCCESS = 'GET_RELATED_QUOTATION_LISTS_SUCCESS'
export const GET_QUOTATION_LIST_SUCCESS = 'GET_QUOTATION_LIST_SUCCESS'
export const ADD_QUOTATION_LIST_SUCCESS = 'ADD_QUOTATION_LIST_SUCCESS'
export const UPDATE_QUOTATION_LIST_SUCCESS = 'UPDATE_QUOTATION_LIST_SUCCESS'
export const DELETE_QUOTATION_LIST_SUCCESS = 'DELETE_QUOTATION_LIST_SUCCESS'
export const STORE_STATES_FROM_LOCAL = 'STORE_STATES_FROM_LOCAL'
export const SET_CURRENT_QUOTATION_ID = 'SET_CURRENT_QUOTATION_ID'

export const API_REQUEST_START = 'API_REQUEST_START'
export const API_REQUEST_SUCCESS = 'API_REQUEST_SUCCESS'
export const API_REQUEST_FAIL = 'API_REQUEST_FAIL'
