import axios from 'axios'
import ResourceModelBase from 'odd-resource_model'
import FetchingDataOptionsService from 'odd-fetching_data_options_service'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'modal_links',
  attributes: [
    'id',
    'position',
    'use_type',
    'alt_text',
    'url',
    'image',
    'version',
    'rules',
    'created_at',
    'updated_at',
  ],
  editableAttributes: ['use_type', 'alt_text', 'url', 'image', 'rules'],
}

export default class ModalLink extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  static useTypes() {
    return axios.get(`${new this().apiBasePath()}/use_type`)
  }

  static allByUseType(options) {
    return axios.get(`${new this().apiBasePath()}?${FetchingDataOptionsService.call(options)}`)
  }

  /**
   * Banner 使用 form data 傳遞資料，因此重新定義 `#save`
   *
   * @param {FormData} formData 內容包含 `modal_link[image]`, `modal_link[alt_text]`
   * @returns {Promise} 回傳 response 或 errors
   */
  save(formData) {
    if (this.isNewRecord()) {
      return axios.post(this.apiBasePath(), formData)
    }
    return axios.put(`${this.apiBasePath()}/${this.id}`, formData)
  }
  // extra methods or helpers here...
}
