<template lang="pug">

.vc-quotation-contact-info-box
  .section-label
    span {{ copyLocaleText('contact_person_information') }}
  .info-box
    .info-wrapper
      .label {{ attributeLocaleText('quotation', 'contact_name') }}
      .info {{ quotation.contact_name }}
    .info-wrapper
      .label {{ attributeLocaleText('quotation', 'contact_email') }}
      .info {{ quotation.contact_email }}
    .info-wrapper
      .label {{ attributeLocaleText('quotation', 'contact_phone') }}
      .info {{ quotation.contact_phone }}
    .info-wrapper
      .label {{ attributeLocaleText('quotation', 'wish_delivery_date') }}
      .info {{ dateTimeLocaleText(quotation.wish_delivery_date, 'YYYY-MM-DD') }}
    .info-wrapper
      .label {{ attributeLocaleText('quotation', 'line_id') }}
      .info {{ quotation.line_id }}
    .info-wrapper
      .label {{ attributeLocaleText('quotation', 'shipping_address') }}
      .info {{ quotation.shipping_address }}

  .section-label
    span {{ copyLocaleText('company_information') }}
  .info-box
    .info-wrapper
      .label {{ attributeLocaleText('quotation', 'company_name') }}
      .info {{ quotation.company_name }}
    .info-wrapper
      .label {{ attributeLocaleText('quotation', 'ein') }}
      .info {{ quotation.ein }}
    .info-wrapper
      .label {{ attributeLocaleText('quotation', 'company_phone') }}
      .info {{ quotation.company_phone }}
    .info-wrapper
      .label {{ attributeLocaleText('quotation', 'company_fax') }}
      .info {{ quotation.company_fax }}
    .info-wrapper
      .label {{ attributeLocaleText('quotation', 'company_address') }}
      .info {{ quotation.company_address }}

</template>

<script>
export default {
  // components: {},
  // mixins: [],
  props: {
    quotation: {
      type: Object,
      required: true,
    },
  },
  // data() {
  //   return {}
  // },
  // computed: {},
  // created() {},
  // mounted() {},
  // methods: {}
}
</script>
