import ResourceModelBase from 'odd-resource_model'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'comments',
  attributes: ['id', 'content', 'is_reply', 'created_at', 'updated_at', 'commentable', 'commenter'],
  editableAttributes: ['content'],
}

export default class Comment extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  // extra methods or helpers here...
  avatarUrl() {
    if (this.commenter['type'] === 'admins') {
      return '/apple-touch-icon-57x57-precomposed.png'
    } else {
      return 'https://via.placeholder.com/80x80?text=No+Image'
    }
  }

  isEdited() {
    return this.created_at !== this.updated_at
  }
}
