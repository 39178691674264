import QuotationDetailContent from '../../components/quotation/detail-content.vue'

export default {
  name: 'quest-quotation-show-container',

  components: {
    QuotationDetailContent,
  },

  // mixins: [],

  // props: {},

  data() {
    return {
      quotationToken: undefined,
      jwt: undefined,
    }
  },

  computed: {
    quotation() {
      return this.$store.getters['quotations/find'](this.quotationToken)
    },

    cartLists() {
      if (this.quotation.lists) {
        return this.quotation.lists.map((list) => this.$store.getters['quotationLists/find'](list.id))
      } else {
        return []
      }
    },
  },

  // created() {},

  mounted() {
    this.quotationToken = this.$el.dataset.quotation
    this.jwt = this.$el.dataset.jwt
    this.$store.dispatch('users/getGuestQuotation', {
      token: this.quotationToken,
      jwt: this.jwt,
    })
  },

  // methods: {}
}
