import axios from 'axios'
import ResourceModelBase from 'odd-resource_model'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'site_config',
  attributes: [
    'meta_tags',
    'trackers',
    'mobile_quick_link_settings',
    'site_contacts',
    'notification_system',
    'store_preferences',
    'app_payment_gateways',
    'environment',
  ],
  editableAttributes: [
    'meta_tags',
    'trackers',
    'mobile_quick_link_settings',
    'site_contacts',
    'notification_system',
    'store_preferences',
  ],
}

export default class SiteConfig extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  static fetchWebsitePreferences() {
    return axios.get(`${new this().apiBasePath()}/website_preferences`)
  }

  static fetchStorePreferences() {
    return axios.get(`${new this().apiBasePath()}/store_preferences`)
  }

  static fetchMobileQuickLinks() {
    return axios.get(`${new this().apiBasePath()}/mobile_quick_links`)
  }

  static updateHolidayDatabase() {
    return axios.post(`${new this().apiBasePath()}/update_holiday_database`)
  }

  static appPaymentGateways() {
    return axios.get(`${new this().apiBasePath()}/app_payment_gateways`)
  }

  static updateDataPosition(data) {
    return axios.put(`${new this().apiBasePath()}/data_positioning`, {
      data: {
        type: OPTIONS.resourceType,
        attributes: data,
      },
    })
  }

  updateWebsitePreferences() {
    const scopes = ['meta_tags', 'trackers']
    let result = {}

    scopes.forEach((scope) => {
      Object.keys(this[scope]).forEach((key) => {
        result[`${scope}.${key}`] = this[scope][key]
      })
    })

    return axios.put(`${this.apiBasePath()}/website_preferences`, this.partialConfigRequestBody(result))
  }

  updateStorePreferences() {
    const scopes = ['notification_system', 'store_preferences']
    let result = {}

    scopes.forEach((scope) => {
      Object.keys(this[scope]).forEach((key) => {
        result[`${scope}.${key}`] = this[scope][key]
      })
    })

    return axios.put(`${this.apiBasePath()}/store_preferences`, this.partialConfigRequestBody(result))
  }

  updateMobileQuickLinks() {
    return axios.put(
      `${this.apiBasePath()}/mobile_quick_links`,
      this.partialConfigRequestBody(this.mobile_quick_link_settings)
    )
  }

  updateSiteContacts() {
    return axios.put(`${this.apiBasePath()}/site_contacts`, this.partialConfigRequestBody(this.site_contacts))
  }

  updateRmaConfig() {
    return axios.put(`${this.apiBasePath()}/rma`, this.partialConfigRequestBody(this.rma))
  }

  // helpers

  /**
   * 僅更新特定 configs 時使用的 request body
   *
   * @param {Object} configs
   * @returns {Object} JSON:API 格式的 request body
   * @memberof SiteConfig
   */
  partialConfigRequestBody(configs) {
    return {
      data: {
        type: OPTIONS.resourceType,
        attributes: configs,
      },
    }
  }
}
